<template>
  <div class="view pa24">
    <el-button
        class="mr10"
        type="primary"
        @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false;
      "
    >+ 添加</el-button>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <!-- <el-table-column type="selection" width="50"/> -->
        <!-- <el-table-column prop="windowsName" align="center" label="用户账号" show-overflow-tooltip/> -->
        <el-table-column prop="bankname" align="center" label="银行名称" show-overflow-tooltip/>
        <!-- <el-table-column prop="createTime" align="center" label="支行名称" show-overflow-tooltip/> -->
        <el-table-column prop="name" align="center" label="开户名" show-overflow-tooltip/>
		<el-table-column prop="bankcard" align="center" label="银行卡号" show-overflow-tooltip/>
		<el-table-column prop="beizhu" align="center" label="备注" show-overflow-tooltip/>
		<el-table-column prop="create_time" align="center" label="添加时间" show-overflow-tooltip/>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
           <!-- <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑</el-button
            > -->
            <customPopconfirm
                confirm-button-text="确定"
                k              cancel-button-text="取消"
                @confirm="del(scope.row.id)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="新增银行卡"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <!-- <el-form-item label="用户账号" prop="account">
          <el-input clearable v-model="ruleForm.account" placeholder="请输入账号"></el-input>
        </el-form-item> -->
        <el-form-item label="银行名称" prop="bank">
          <el-input clearable v-model="ruleForm.bank" placeholder="请输入银行名称"></el-input>
        </el-form-item>
	<!-- 	<el-form-item label="支行名称" prop="zhihang">
		  <el-input clearable v-model="ruleForm.zhihang" placeholder="请输入支行名称"></el-input>
		</el-form-item> -->
		<el-form-item label="开户名" prop="name">
		  <el-input clearable v-model="ruleForm.name" placeholder="请输入开户名"></el-input>
		</el-form-item>
		<el-form-item label="银行卡号" prop="ka">
		  <el-input clearable v-model="ruleForm.ka" placeholder="请输入银行卡号"></el-input>
		</el-form-item>
		<el-form-item label="备注" prop="desc">
		  <el-input clearable v-model="ruleForm.desc"  type="textarea" :rows="4" placeholder="请输入备注"></el-input>
		</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {bankLists,bankDel,bankAdd} from "@/api/settlement";
let ac;
export default {
  name:'windowSet',
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      centerDialogVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      ruleForm: {
        account: "",
		bank: "",
		zhihang: "",
		name: "",
		ka: "",
		desc: "",
      },
      rules: {
        // account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        bank: [{ required: true, message: "请输入银行名称", trigger: "blur" }],
		// zhihang: [{ required: true, message: "请输入支行名称", trigger: "blur" }],
		name: [{ required: true, message: "请输入开户名", trigger: "blur" }],
		ka: [{ required: true, message: "请输入银行卡号", trigger: "blur" }],
      },
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  mounted(){
    this.queryPage();
  },

  methods: {
    async queryPage() {
      let data = {
        page_size: this.pageSize,
        page_no: this.currentPage,
      };
      try {
        this.loading = true;
        const result = await bankLists(data);
        this.loading = false;
        const { count, lists } = result.data;
        this.tableData = lists;
        this.total = count;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.account = data.windowsName ;
      this.ruleForm.bank = data.windowsPerson;
      this.ruleForm.zhihang = data.status;
      this.ruleForm.name = data.officeId
	  this.ruleForm.ka = data.officeId
	  this.ruleForm.desc = data.officeId
      this.bdetermine = b;
      this.bEdit = b;
      this.windowsConfigId = data.windowsConfigId
    },
    /**@method 添加 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              // account:this.ruleForm.account,
              bankname:this.ruleForm.bank,
              // zhihang:this.ruleForm.zhihang,
              name:this.ruleForm.name,
			  bankcard:this.ruleForm.ka,
			  beizhu:this.ruleForm.desc,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.id = this.id
              await bankAdd(data);
            } else {
              await bankAdd(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await bankDel({ id: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        account: "",
        bank:"",
        zhihang: "",
        name:"",
		desc: "",
        ka:"",
      }),
          this.centerDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>




